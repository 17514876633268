<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";



import avatar_icon from '@/assets/images/users/avatar-1.jpg'

import TabBasicInfoEdit from './components/tab_agent_edit_basic_info.vue'
import TabFeesEdit from './components/tab_agent_edit_fee.vue'
import TabFinancialEdit from './components/tab_agent_edit_financial_info.vue'
import TabTaxEdit from './components/tab_agent_edit_tax_info.vue'
import TabNotesEdit from './components/tab_agent_edit_notes.vue'
import TabTeamEdit from './components/tab_agent_edit_team.vue'

import { getBrokerageApi } from "@/api/brokerage"


/**
 * New Agent component
 */
export default {
  page: {
    title: "Agent",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  props: {
    
    agent_id: {
      type: Number
    }
  },

  data() {
    return {
      btn_text : 'Save',
      title: "",
      items: [
      {
          text: "Brokerage",
          href: "/",
        },
        {
          text: "Agent List",
          href: "/brokerage/agent",
        },
        {
          text: "New Agent",
          active: true,
        },
      ],
      avatar: avatar_icon,
      edit: false,
      agent : {
        description : '',
        picture : avatar_icon,
        fee_items : [],
        fields : {
          business    :{},
          financial   : {},
          tax         : {},
          basic       : {},
          key_date    : {
            key_date_registration :'',
            key_date_fee_start    : ''
          }
        }
      },

      business_info : {
      }, 
      financial : {

      },
      garnishee : [],
      tax : {},

      deleted_fields : [],
      tab_idx : 0
    };
  },

  validations: {

  },

  computed: {
    /**
     * Total no. of records
     */
     show_prev_btn() {
      return this.tab_idx > 0 && !this.edit
    },
  },



  components: {
    Layout,
    PageHeader,
    TabBasicInfoEdit,
    TabTaxEdit,
    TabFeesEdit,
    TabFinancialEdit,
    TabNotesEdit,
    TabTeamEdit

  },



  methods: {

    formSubmit() {

      let result = true
     
     
      let ntabs = this.$refs.infoTabs.tabs.length
      if (this.tab_idx < ntabs -1 &&  !this.edit) {
        this.$refs.infoTabs.tabs[++this.tab_idx].activate()
        return
      }

     
      
      this.agent.picture = this.agent.avatar
      result = this.$refs.basic_edit_tab.reset()
      result = this.$refs.basic_edit_tab.validate()
      if (result) {
        this.$refs.infoTabs.tabs[0].activate()
        return
      }

      this.agent.fee_items = []
      let fis = this.$refs.monthly_fee_edit_tab.get_fee_items()
      this.agent.fee_items.push(...fis)

      let deal_fees = this.$refs.deal_fee_edit_tab.get_fee_items()
      this.agent.fee_items.push(...deal_fees)

      let listing_fees = this.$refs.listing_fee_edit_tab.get_fee_items()
      this.agent.fee_items.push(...listing_fees)

      let pm_fees = this.$refs.pm_fee_edit_tab.get_fee_items()
      this.agent.fee_items.push(...pm_fees)
      
      this.garnishee.map(e => {
        this.agent.fields.garnishee [e.field]  =   e.supplier_id+'_'+e.supplier_name+'_'+Number(e.amount ||0)+'_'+Number(e.portion ||0)
      })


      if (result == false) {
        this.agent.business       = this.business_info
        this.agent.financial      = this.$refs.agent_financial_tab.financial
        this.agent.tax            = this.$refs.agent_tax_info_tab.tax


        if (this.agent.id) {
          this.agent.agent_id    = this.agent.id
          this.agent.pm_mgt_code = this.agent.property_mgt_code
          getBrokerageApi().agentApi.edit_agent(this.agent, this.deleted_fields).then((res) => {
            if (res.errCode == 0) {
              this.$router.push({ path: '/brokerage/agent' })
            } else {
              this.$alertify.error("Add Agent Failed " + res.errCode);
            }
          })
        } else {
          getBrokerageApi().agentApi.agent_create(this.agent).then((res) => {
            if (res.errCode == 0) {
              this.$router.push({ path: '/brokerage/agent' })
            } else {
              this.$alertify.error("Add Agent Failed " + res.errCode);
            }
          })
        }

      }

      this.$v.$reset()
    },


    onPre() {
      this.$refs.infoTabs.tabs[--this.tab_idx].activate()
    },

    onTabChange(new_tab, old_tab) {
      (old_tab);
      (new_tab);
     
      this.tab_idx = new_tab
      if (!this.edit) {
        this.btn_text = new_tab == this.$refs.infoTabs.tabs.length -1 ? 'Save':'Next'
      }
    }

  },


  created() {
   
    
   

  },

  mounted() {

    this.edit = this.agent_id ? true : false

    if (this.agent_id) {
      getBrokerageApi().agentApi.agent_detail({ agent_id: this.agent_id}).then((res) => {
        if (res.errCode == 0) {
          this.agent =  res.data
          if (!this.agent.fields) {
            this.agent.fields = {
              business  : {},
              financial : {},
              tax       : {},
              basic     : {},
              key_date  : {
                key_date_registration :'',
                key_date_fee_start    : ''
              }
            }
          }

          if (!this.agent.fields.basic) {
            this.agent.fields.basic = {}
          }
          
          if (!this.agent.fields.financial) {
            this.agent.fields.financial = {}
          }
          if (!this.agent.fields.garnishee) {
            this.agent.fields.garnishee = {}
          } 
          

          if (!this.agent.fields.business_info) {
            this.agent.fields.business = {}
          }

          if (!this.agent.fields.tax) {
            this.agent.fields.tax = {}
          }

          if (!this.agent.fields.key_dates) {
            this.agent.fields.key_date = {}
          }

          if (!this.agent.description) {
            this.agent.description = ''
          }
          
          this.business       = this.agent.fields.business_info
          this.financial      = this.agent.fields.financial
          
          this.tax            = this.agent.fields.tax
          this.tax.first_name = this.agent.first_name
          this.tax.last_name  = this.agent.last_name

        
          this.garnishee      = Object.keys( this.agent.fields.garnishee).map(e => ({field : e, value : this.agent.fields.garnishee[e]}))

          this.garnishee.map(e => {
              let v           = e.value.split("_")
              e.supplier_code = e.field
              e.supplier_id   = v.length > 0 ? v[0] : ''
              e.supplier_name = v.length > 1 ? v[1] : ''
              e.amount        = v.length > 2 ? Number(v[2]) : 0
              e.portion       = v.length > 3 ? Number(v[3]) : 0
          })


          this.$refs.agent_financial_tab.update_agent(this.agent)
          this.$refs.agent_tax_info_tab.update_agent(this.agent)
          this.$refs.basic_edit_tab.update_agent(this.agent)
          this.$refs.agent_notes_tab.update_agent(this.agent)
          this.$refs.agent_team_tab.update_agent(this.agent)
          
        }
      })
    } else {
      this.btn_text = 'Next'
    }
    
  
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">

            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-tabs ref="infoTabs" class="mt-n5 pt-5" content-class="card card-body mt-3" nav-class="nav-tabs-custom"
                nav-wrapper-class="nav-tabs-custom" @activate-tab="onTabChange">

                <b-tab title="Basic" active>
                  <TabBasicInfoEdit :agent="agent" ref="basic_edit_tab" />
                </b-tab>

                <b-tab title="Tax">
                  <TabTaxEdit :agent="agent" :tax="tax" ref="agent_tax_info_tab"/>
                </b-tab>
                <b-tab title="Financial">
                  <TabFinancialEdit :agent="agent" :financial="financial" :garnishee="garnishee"  :del_fields="deleted_fields" ref="agent_financial_tab"/>
                </b-tab>

                <b-tab title="Notes">
                  <TabNotesEdit :agent="agent"  ref="agent_notes_tab"/>
                </b-tab>

                <b-tab title="Team">
                  <TabTeamEdit :agent="agent"  ref="agent_team_tab"/>
                </b-tab>
                
                <b-tab title="Monthly Fee">
                  <TabFeesEdit :agent="agent" ref="monthly_fee_edit_tab" :category="'MONTHLY'"/>
                </b-tab>
                <b-tab title="Deal Fee">
                  <TabFeesEdit :agent="agent" ref="deal_fee_edit_tab" :category="'DEAL'" />
                </b-tab>
                <b-tab title="Listing Fee">
                  <TabFeesEdit :agent="agent" ref="listing_fee_edit_tab" :category="'LISTING'"/>
                </b-tab>

                <b-tab title="PM Fee">
                  <TabFeesEdit :agent="agent" ref="pm_fee_edit_tab" :category="'PM'" />
                </b-tab>

              </b-tabs>

              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex flex-wrap gap-2  justify-content-end align-items-right">
                  <button  type="button" class="btn btn-secondary w-sm" @click="$router.push({name : 'brokerage-agent'})">Cancel</button>
                  <button ref="prev_btn"  class="btn btn-primary w-sm" v-if="show_prev_btn" @click="onPre"> Prev</button>
                  <button ref="submit_btn" type="submit" class="btn btn-primary w-sm"> {{ btn_text }} </button>
                </div>
                </div>
              </div>
            </form>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>
